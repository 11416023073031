@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

@keyframes flutuar {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}